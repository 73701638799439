import React, { useState } from "react";
import { Button, Card, Container, Form, Stack } from "react-bootstrap";

import "./Home.scss";

interface Resource {
  title: string;
  link: string;
}

interface Topic {
  title: string;
  resources: Resource[];
}

const defaultResource: Resource = { title: "", link: "" };
const defaultTopic: Topic = { title: "", resources: [{ ...defaultResource }] };

const Home = () => {
  const [topics, setTopics] = useState([{ ...defaultTopic }]);

  const addTopic = () => {
    setTopics([...topics, { ...defaultTopic }]);
  };

  const deleteTopic = (idx: number) => {
    setTopics(topics.filter((topic: Topic, i: number) => idx !== i));
  };

  const addResource = (idx: number) => {
    topics[idx].resources = topics[idx].resources.concat({ ...defaultResource });
    setTopics([...topics]);
  };

  const deleteResource = (topicIdx: number, resourceIdx: number) => {
    topics[topicIdx].resources = topics[topicIdx].resources.filter(
      (resource: Resource, i: number) => resourceIdx !== i,
    );
    setTopics([...topics]);
  };

  const save = () => {
    console.log(topics);
  };

  return (
    <Container className="home">
      {topics.map((topic, topicIdx) => (
        <Card className="topic-card" key={topicIdx}>
          <Form className="topic-form">
            <Form.Group className="mb-3" controlId="form-group-topic">
              <Form.Label>Topic</Form.Label>
              <Button className="remove-topic-button" onClick={() => deleteTopic(topicIdx)} variant="link">
                [X]
              </Button>
              <Form.Control placeholder="Enter topic" type="topic" />
            </Form.Group>
            {topic.resources.map((resource, resourceIdx) => (
              <Form.Group className="mb-3" controlId="form-group-link" key={resourceIdx}>
                <Form.Label>Resource #{resourceIdx + 1}</Form.Label>
                <Button
                  className="remove-resource-button"
                  onClick={() => deleteResource(topicIdx, resourceIdx)}
                  variant="link">
                  [X]
                </Button>
                <Stack gap={1}>
                  <Form.Control placeholder="Resource title" type="resource-title" />
                  <Form.Control placeholder="Resource link" type="resource-link" />
                </Stack>
              </Form.Group>
            ))}
          </Form>
          <Button className="add-resource-button" onClick={() => addResource(topicIdx)} variant="link">
            Add a resource
          </Button>
        </Card>
      ))}
      <Stack className="button-group" gap={1}>
        <Button onClick={addTopic} variant="primary">
          Add Topic
        </Button>
        <Button onClick={save} variant="link">
          Save
        </Button>
      </Stack>
    </Container>
  );
};

export default Home;
