import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import { AppRoutes } from "AppRoutes";
import map from "assets/img/map.png";

const Landing = () => {
  return (
    <Container>
      <img alt="Map" src={map} />
      <h2>
        <i>
          "A map of the internet for hungry learners."
          <ul>
            <li>
              <Link to={AppRoutes.SIGN_UP}>Join Us</Link>
            </li>
            <li>
              <Link to={AppRoutes.LOGIN}>Already gilded? Log in</Link>
            </li>
            <li>
              <Link to={AppRoutes.HOME}>Home (Logged in)</Link>
            </li>
            <li>
              <Link to={AppRoutes.PROFILE}>Preview Your Profile</Link>
            </li>
          </ul>
        </i>
      </h2>
    </Container>
  );
};

export default Landing;
