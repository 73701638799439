import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";

import logo from "aseets/img/gild-logo.png";

const Layout = () => {
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img alt="" className="d-inline-block align-top" height="30" src={logo} width="30" />
            gild
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default Layout;
