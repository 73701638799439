import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import App from "App";
import reportWebVitals from "reportWebVitals";

const firebaseConfig = {
  apiKey: "AIzaSyB20_QyGeCmr-D3lXvV5ChwJb7steT8fuY",
  authDomain: "gild-6bbfd.firebaseapp.com",
  projectId: "gild-6bbfd",
  storageBucket: "gild-6bbfd.appspot.com",
  messagingSenderId: "1099286569687",
  appId: "1:1099286569687:web:25dfe6b882bdf8f0308692",
  measurementId: "G-N0ZHWN0YVN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
