import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

interface Link {
  url: string;
  title: string;
}

interface Section {
  title: string;
  links: [Link];
}

interface Links {
  userId: string;
  profile: {
    name: string;
  };
  sections: [Section];
}

const defaultLinks: Links = {
  userId: "",
  profile: {
    name: "",
  },
  sections: [
    {
      title: "",
      links: [
        {
          url: "",
          title: "",
        },
      ],
    },
  ],
};

const Profile = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { userId } = useParams();
  const [data, setData] = useState(defaultLinks);

  useEffect(() => {
    const getData = async () => {
      const res = await Axios.get(`${apiUrl}/links/${userId}`);
      setData(res.data);
    };
    getData();
  }, [userId, apiUrl]);

  return (
    <Container>
      {data.profile.name ? (
        <div>
          <strong>{data.profile.name}'s gild</strong>
          <div>
            {data.sections.map((data: Section) => (
              <div className="section" key={data.title}>
                <h1>{data.title}</h1>
                <ul>
                  {data.links.map((link: Link, i: number) => (
                    <li key={i}>
                      <a href={link.url}>{link.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>Fuck yah life, bing bong</p>
      )}
    </Container>
  );
};

export default Profile;
