import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "components/Layout/Layout";
import Landing from "pages/Landing/Landing";
import Home from "pages/Home/Home";
import SignUp from "pages/SignUp/SignUp";
import Login from "pages/Login/Login";
import NotFound from "pages/NotFound/NotFound";
import Profile from "pages/Profile/Profile";

import { AppRoutes } from "./AppRoutes";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />} path="/">
          <Route element={<Landing />} index />
          <Route element={<Home />} path={AppRoutes.HOME} />
          <Route element={<Profile />} path={AppRoutes.PROFILE} />
          <Route element={<SignUp />} path={AppRoutes.SIGN_UP} />
          <Route element={<Login />} path={AppRoutes.LOGIN} />
          <Route element={<NotFound />} path="*" />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
